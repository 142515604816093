import {
  AntDesignCloudServerOutlined,
  BasilServerOutline,
  SimpleLineIconsScreenSmartphone,
  TeenyiconsGitOutline,
} from '@/components/icons/Icons';
import { IconContainer } from '@/components/icons/IconsContainer';
import { reactMarkdownConfig } from '@/lib/utils/strapi';
import { motion } from 'framer-motion';
import { TFunction } from 'i18next';
import ReactMarkdown from 'react-markdown';

const containerVariants = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0, transition: { delay: 0.3 } },
};

const ServicesSection = ({
  t,
  frontend,
  backend,
  devInf,
  devOps,
  mainText,
}: {
  t: TFunction<'translation', undefined>;
  frontend: string;
  backend: string;
  devInf: string;
  devOps: string;
  mainText: string;
}) => (
  <div
    className="bg-gradient-to-t from-content2 to-content1 text-current text-center py-12 lg:px-6" // Tailwind classes for styling
  >
    <div className="container mx-auto px-4 lg:px-8">
      <motion.div initial="hidden" animate="visible" variants={containerVariants}>
        <h2 className="text-2xl mt-5 mb-10 drop-shadow-[0_1.2px_1.2px_rgba(255,10,0,0.9)]">
          Services
        </h2>
        <div className="my-5 px-8 text-left">
          <ReactMarkdown className="prose max-w-full " {...reactMarkdownConfig}>
            {mainText}
          </ReactMarkdown>
        </div>
        <h3 className="mt-14 font-semibold">{t('services.intro_tech')}</h3>
        <div className="mt-10 flex flex-wrap justify-center">
          <Section icon={SimpleLineIconsScreenSmartphone} title="Frontend" text={frontend} />
          <Section icon={BasilServerOutline} title="Backend" text={backend} />
          <Section icon={TeenyiconsGitOutline} title="Dev Inf" text={devInf} />
          <Section icon={AntDesignCloudServerOutlined} title="Dev Ops" text={devOps} />
          {/* Repeat for other services */}
        </div>
      </motion.div>
      {/* Other sections with motion.div */}
    </div>
  </div>
);

// Define TypeScript interface for the props
type SectionProps = {
  icon: React.ElementType;
  title: string;
  text: string;
  text2?: string;
};

// Section component
const Section: React.FC<SectionProps> = ({ icon, title, text, text2 }) => {
  return (
    <div className="space-x-unit-2 w-full md:w-1/2 lg:w-1/4 px-4 mb-5">
      <div className="my-3 flex items-center justify-center rounded-full">
        <IconContainer
          Icon={icon}
          iconProps={{ className: 'w-6 h-6 sm:w-14 sm:h-14 fill-current text-blue-500' }}
        />
      </div>
      <h4 className="font-bold">{title}</h4>
      <ReactMarkdown className="prose max-w-full" {...reactMarkdownConfig}>
        {text}
      </ReactMarkdown>
      {text2 && <p className="text-faded mt-1">{text2}</p>}
    </div>
  );
};

export default ServicesSection;
