import {
  AnchorHTMLAttributes,
  HTMLAttributes,
  ImgHTMLAttributes,
  ReactElement,
  TableHTMLAttributes,
  TdHTMLAttributes,
  ThHTMLAttributes,
} from 'react';
import remarkGfm from 'remark-gfm';
import Image from 'next/image';
import { logging } from '@/lib/logging/LogManager';
import { extractPath, logError } from '@/lib/logging/Logger';
import rehypeRaw from 'rehype-raw';

const logger = logging.getLogger('strapi', extractPath(__filename));

// Parses the JSON returned by a network request
export const parseJSON = (resp: { status?: number; json?: any }) =>
  resp.json ? resp.json() : resp;

// Checks if a network request came back fine, and throws an error if not
export const checkStatus = async (resp: Response, name: string) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }
  logError(logger, 'Error fetching cms', { resp: resp.statusText, name });
  throw resp;
};

export const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_RO_TOKEN}`,
};

type CustomParagraphProps = HTMLAttributes<HTMLParagraphElement>;

export const CustomParagraph = (props: CustomParagraphProps) => (
  <p {...props} className={`not-prose mb-4 text-md text-foreground ${props.className || ''}`}>
    {props.children}
  </p>
);

const CustomH1 = (props: HTMLAttributes<HTMLHeadingElement>) => (
  <h1 className="not-prose text-3xl text-foreground font-bold mb-4">{props.children}</h1>
);

const CustomH2 = (props: HTMLAttributes<HTMLHeadingElement>) => (
  <h2 className="not-prose text-2xl text-foreground font-bold mb-4">{props.children}</h2>
);

const CustomH3 = (props: HTMLAttributes<HTMLHeadingElement>) => (
  <h3 className="not-prose text-xl text-foreground font-bold mb-4">{props.children}</h3>
);

export const CustomH4 = (props: HTMLAttributes<HTMLHeadingElement>) => (
  <h4 className={`not-prose text-lg text-foreground font-bold mb-4 ${props.className || ''}`}>
    {props.children}
  </h4>
);

export const CustomH5 = (props: HTMLAttributes<HTMLHeadingElement>) => (
  <h5 className={`not-prose text-base text-foreground font-bold mb-4 ${props.className || ''}`}>
    {props.children}
  </h5>
);

const CustomH6 = (props: HTMLAttributes<HTMLHeadingElement>) => (
  <h6 className="not-prose text-sm text-foreground font-bold mb-4">{props.children}</h6>
);

const CustomEmphasis = (props: HTMLAttributes<HTMLElement>) => (
  <em className="not-prose text-foreground italic">{props.children}</em>
);

const CustomStrong = (props: HTMLAttributes<HTMLElement>) => (
  <strong className={`not-prose text-foreground font-bold ${props.className || ''}`}>
    {props.children}
  </strong>
);

const CustomLink = (props: AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <a href={props.href} className="not-prose text-foreground underline" target="_blank">
    {props.children}
  </a>
);

const CustomTable = (props: TableHTMLAttributes<HTMLTableElement>) => (
  // <div className="overflow-x-auto">
  <table className="not-prose table-auto mb-4 text-foreground w-full text-xs sm:text-sm">
    {props.children}
  </table>
  // </div>
);

const CustomTableRow = (props: HTMLAttributes<HTMLTableRowElement>) => (
  <tr className="not-prose bg-gray-100 border-b border-gray-300 text-foreground">
    {props.children}
  </tr>
);

const CustomTableCell = (props: TdHTMLAttributes<HTMLTableCellElement>) => (
  <td className="not-prose px-2 sm:px-4 py-2 border-y border-gray-300 text-foreground">
    {props.children}
  </td>
);

const CustomTableHeader = (props: ThHTMLAttributes<HTMLTableCaptionElement>) => (
  <th className="not-prose pl-2 sm:pl-4 pr-4 pt-3 pb-3 border-l border-dashed text-foreground">
    {props.children}
  </th>
);

const CustomTableHead = (props: ThHTMLAttributes<HTMLTableCaptionElement>) => (
  <thead className="not-prose border rounded-lg">{props.children}</thead>
);

const CustomImage = (props: ImgHTMLAttributes<HTMLImageElement>) => (
  <span className="my-4 relative">
    <Image
      className="not-prose w-full max-w-3xl p-1 h-auto mx-auto rounded-lg shadow-md border-2 border-gray-300 mb-4"
      src={props.src!}
      alt={props.alt!}
      title={props.title}
      width="0"
      height="0"
      sizes="100vw"
    />
    {props.title && <p className="text-center text-sm mt-2 text-foreground">{props.title}</p>}
  </span>
);

export const components = {
  p: CustomParagraph,
  em: CustomEmphasis,
  strong: CustomStrong,
  a: CustomLink,
  table: CustomTable,
  //tr: CustomTableRow,
  thead: CustomTableHead,
  td: CustomTableCell,
  th: CustomTableHeader,
  img: CustomImage,
  h1: CustomH1,
  h2: CustomH2,
  h3: CustomH3,
  h4: CustomH4,
  h5: CustomH5,
  h6: CustomH6,
  ul: ({ ...props }) => (
    <ul
      className="not-prose ps-6 mb-6 text-foreground"
      style={{
        display: 'block',
        listStyleType: 'disc',
        // paddingInlineStart: '2rem',
      }}
      {...props}
    />
  ),
  ol: ({ ...props }) => (
    <ol
      style={{
        display: 'block',
        listStyleType: 'decimal',
        // paddingInlineStart: '2rem',
      }}
      className="not-prose text-md ps-6 mb-6 text-foreground"
      {...props}
    />
  ),
};

export const reactMarkdownConfig = {
  components,
  remarkPlugins: [remarkGfm],
  rehypePlugins: [rehypeRaw],
};

export const getFromCMS = async (
  name: string,
  locale?: string
): Promise<{ id?: string; attributes?: { text: string } }> => {
  let queryParams = '';
  if (locale) {
    queryParams = `?locale=${locale}`;
  }
  // console.log(`${process.env.NEXT_PUBLIC_STRAPI_SERVER}/api/${name}${queryParams}`);
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_STRAPI_SERVER}/api/${name}${queryParams}`,
    {
      headers,
      method: 'GET',
      next: { revalidate: 360 },
    }
  );
  try {
    const statusResponse = await checkStatus(response, name);
    return (await parseJSON(statusResponse)).data;
  } catch (err) {
    return {};
  }
};

export const getFromCMSCollection = async (
  name: string,
  locale?: string,
  extraQueryParams?: string[]
): Promise<{ id: string; attributes: { publishedAt: Date; text: string } }[]> => {
  let queryParams = '?sort[0]=publishedAt:desc';
  if (extraQueryParams) {
    queryParams = queryParams.concat('&', extraQueryParams.join('&'));
  }
  if (locale) {
    queryParams = `${queryParams}&locale=${locale}`;
  }
  // console.log(`${process.env.NEXT_PUBLIC_STRAPI_SERVER}/api/${name}${queryParams}`);
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_STRAPI_SERVER}/api/${name}${queryParams}`,
    {
      headers,
      method: 'GET',
      next: { revalidate: 360 },
    }
  );

  try {
    const statusResponse = await checkStatus(response, name);
    return (await parseJSON(statusResponse)).data;
  } catch (err) {
    return [];
  }
};

// This function takes a component (Comp) and returns a new component with additional className(s)
export const withCustomTextColor = (Comp: React.ElementType, additionalClassName: string) => {
  // Return a new functional component
  // eslint-disable-next-line react/display-name
  return (props: any): ReactElement => {
    // Merge the original className with the new one
    const className = `${props.className || ''} ${additionalClassName}`.trim();
    // Clone the component with the new className and any additional props

    return <Comp {...props} className={className} />;
  };
};
