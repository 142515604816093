'use client';

import { useTheme } from 'next-themes';
import { useTranslation } from 'react-i18next';
import Hero from './Hero';
import './HomePage.css';
import { PortfolioSection } from './PortfolioSection';
import ServicesSection from './ServiceSection';
import { ExtProjects } from './page';
import ContactSection from './ContactSection';

export default function HomePage({
  projects,
  welcome,
  frontend,
  backend,
  devOps,
  devInf,
  mainText,
  gigs,
}: {
  projects: ExtProjects;
  welcome: string;
  frontend: string;
  backend: string;
  devOps: string;
  devInf: string;
  mainText: string;
  gigs: string;
}) {
  // const { theme, setTheme } = useTheme();
  const { t } = useTranslation();
  // console.log(JSON.stringify(projects, undefined, 2));
  return (
    <div className="container mx-auto">
      <Hero />
      {/* {t('Welcome')} */}
      <section id="services">
        <ServicesSection
          frontend={frontend}
          backend={backend}
          devOps={devOps}
          devInf={devInf}
          mainText={mainText}
          t={t}
        />
      </section>
      <section id="portfolio">
        <PortfolioSection projects={projects} gigs={gigs} />
      </section>
      <section id="contact">
        <ContactSection t={t} />
      </section>
    </div>
  );
}
