'use client';
import { useError } from '@/lib/context/ErrorContextProvider.client';
import { useLoading } from '@/lib/context/LoadingContextProvider.client';
import { logging } from '@/lib/logging/LogManager';
import { logError } from '@/lib/logging/Logger';
import { reactMarkdownConfig } from '@/lib/utils/strapi';
import {
  Badge,
  Button,
  Checkbox,
  CheckboxGroup,
  Chip,
  Input,
  Spinner,
  Textarea,
} from '@nextui-org/react';
import { motion } from 'framer-motion';
import { TFunction } from 'i18next';
import Image from 'next/image';
import ReactMarkdown from 'react-markdown';
import BackgroundImage from './BackgroundImage';
import { useState } from 'react';
import { ZodType, z } from 'zod';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
const logger = logging.getLogger('fe-contactSection');
import { inspect } from 'util';
import AlertBox from '@/components/AlertBox';

const containerVariants = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0, transition: { delay: 0.3 } },
};

enum MessageStatus {
  Sent = 1,
  Error = 2,
  NotSend = 4,
}

type ContactFormData = {
  name: string;
  email: string;
  telephone?: string;
  message: string;
  checked: boolean;
};

type State = {
  // isVisible: boolean;
  // validated: boolean;
  sent: MessageStatus;
  interval?: NodeJS.Timeout;
};

const ContactSection = ({
  t,
  // frontend,
  // backend,
  // devInf,
  // devOps,
  // mainText,
}: {
  t: TFunction<'translation', undefined>;
  // frontend: string;
  // backend: string;
  // devInf: string;
  // devOps: string;
  // mainText: string;
}) => {
  const { loading, setLoading } = useLoading();
  const { error, setError } = useError();
  const [state, setState] = useState<State>({
    // isVisible: true,
    // validated: false,
    sent: MessageStatus.NotSend,
  });

  // const phoneRegex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/);

  const FormSchema: ZodType<ContactFormData> = z.object({
    email: z
      .string({
        required_error: t('contact.form.email'),
      })
      .email(t('contact.form.email')),
    name: z
      .string({
        required_error: t('contact.form.name'),
        invalid_type_error: 'invalid name',
      })
      .min(3, t('contact.form.too-short'))
      .max(50, t('contact.form.too-long')),
    telephone: z
      .string({
        required_error: 'required field',
        invalid_type_error: 'Tel too long',
      })
      .max(40, t('contact.form.too-long'))
      .optional(),

    message: z
      .string({
        required_error: t('contact.form.message'),
      })
      .min(8, t('contact.form.too-short'))
      .max(500, t('contact.form.too-long')),
    checked: z
      .boolean({ required_error: t('contact.form.check_error') })
      .refine(val => val === true, {
        message: t('contact.form.check_error'),
      }),
  });
  // const { t } = useTranslation();

  const {
    register,
    handleSubmit: handleReactHookFormSubmit,
    formState: { errors, isValid, isDirty },
    reset,
    control,
  } = useForm<ContactFormData>({
    resolver: zodResolver(FormSchema), // Apply the zodResolver
  });

  const handleSubmit: SubmitHandler<ContactFormData> = async (data: ContactFormData) => {
    // console.error('handle submit');
    if (loading) return;
    setLoading(true);
    setError(false);

    try {
      const res = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (res.ok) {
        setState({ ...state, sent: MessageStatus.Sent });
        // setContent('');
        // ref.current?.setMarkdown('');
        // setUserTags([]);
        // router.refresh();
        reset();
        setLoading(false);
        return;
      }
      throw Error(res.statusText);
    } catch (error) {
      setState({ ...state, sent: MessageStatus.Error });
      logError(logger, 'Fehler beim Anlegen der Nachricht', error);
      setError('Fehler beim Anlegen der Nachricht');
    } finally {
      setLoading(false);
    }
  };
  // console.log(inspect(errors));
  return (
    <div
      className="bg-gradient-to-t from-content1 to-content2 text-current text-center" // Tailwind classes for styling
    >
      <BackgroundImage
        image={
          <Image
            src={'/map-image.png'}
            alt={'Map'}
            className="object-cover object-center opacity-30 bg-gradient-to-t from-black to-background w-full h-full"
            fill
          />
        }
        className={''}
      >
        <div className="container mx-auto px-4 py-12 lg:px-14">
          <motion.div
            className="py-12 mb-12"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            <form onSubmit={handleReactHookFormSubmit(handleSubmit)} className="w-full">
              <h2 className="text-2xl mt-5 mb-10 drop-shadow-[0_1.2px_1.2px_rgba(255,10,0,0.9)]">
                {t('contact.contact')}
              </h2>
              <div className="px-8">
                <div className="my-5 text-left py-5">
                  <ReactMarkdown className="prose max-w-full " {...reactMarkdownConfig}>
                    {t('contact.cta')}
                  </ReactMarkdown>
                </div>
                {/* <h3 className="mt-14 font-semibold">{t('services.intro_tech')}</h3> */}
                <div className="mt-6 grid gap-6 lg:grid-cols-2">
                  <div className="flex flex-col space-y-6">
                    <Input
                      type="email"
                      label="Email"
                      placeholder={t('email')}
                      errorMessage={errors.email?.message}
                      {...register('email')}
                      isInvalid={Boolean(errors.email?.message)}
                    />
                    <Input
                      type="text"
                      label="Name"
                      placeholder={t('name')}
                      errorMessage={errors.name?.message}
                      {...register('name')}
                      isInvalid={Boolean(errors.name?.message)}
                    />
                    <Input
                      type="tex"
                      label="Tel"
                      placeholder={t('telephone-number') + ' (' + t('optional') + ')'}
                      errorMessage={errors.telephone?.message}
                      {...register('telephone')}
                      isInvalid={Boolean(errors.telephone?.message)}
                    />
                  </div>
                  <div className="flex flex-col">
                    {/* <div className="flex-grow"> */}
                    <Textarea
                      // minRows={9}
                      // maxRows={100}
                      disableAutosize
                      label={t('contact.form.message-label')}
                      placeholder={t('contact.enter-message')}
                      classNames={{
                        base: 'flex-grow',
                        // input: 'flex-grow flex-1',
                        inputWrapper: 'flex-grow',
                      }}
                      errorMessage={errors.message?.message}
                      isInvalid={Boolean(errors.message?.message)}
                      {...register('message')}
                    />

                    {/* <TextAreaGroup name="bio" label="Bio" /> */}
                    {/* </div> */}
                  </div>
                </div>
                {/* <Card className="mt-8">
                <CardBody>
                  <Checkbox>Option</Checkbox>
                </CardBody>
              </Card> */}
                <Controller
                  control={control}
                  name="checked"
                  render={({ field: { onChange, value } }) => (
                    <div className="mt-8 px-3 py-4 sm:py-2 rounded-2xl border-2 bg-content2/[.9]">
                      <CheckboxGroup
                        errorMessage={errors.checked?.message}
                        isInvalid={Boolean(errors.checked?.message)}
                      >
                        <Checkbox onChange={onChange} isSelected={value}>
                          <span className="text-foreground-500 text-small">
                            {t('contact.form.check')}
                          </span>
                        </Checkbox>
                      </CheckboxGroup>
                    </div>
                  )}
                />

                <div className="text-left">
                  <Button
                    color="primary"
                    variant="flat"
                    type="submit"
                    // isDisabled={!isDirty || !isValid}
                    className="min-w-24 mt-12"
                  >
                    {loading ? <Spinner size="sm" /> : t('contact.send-message')}
                  </Button>
                </div>
              </div>
            </form>
            {state.sent === MessageStatus.Sent ? (
              <AlertBox
                type="success"
                timer={4000} // Omit for a persistent alert
              >
                <Chip color="success">{t('contact.message-sent')}</Chip>
              </AlertBox>
            ) : state.sent === MessageStatus.Error ? (
              <AlertBox
                type="alert"
                timer={4000} // Omit for a persistent alert
              >
                <h1>
                  <motion.div initial="hidden" animate="visible" variants={containerVariants}>
                    <Chip color="danger">{t('contact.message-error')}</Chip>
                  </motion.div>
                </h1>
              </AlertBox>
            ) : null}
          </motion.div>
          {/* <div>{inspect(errors)}</div> */}
        </div>
      </BackgroundImage>
      {/* Other sections with motion.div */}
    </div>
  );
};

// interface InputGroup1Props extends InputHTMLAttributes<HTMLInputElement> {
//   label: string;
//   name: string;
//   value?: string;
//   onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
// }

// function InputGroup1({
//   label,
//   name,
//   value,
//   onChange,
//   type = 'text',
//   disabled,
//   ...props // Capture additional props here
// }: InputGroup1Props) {
//   return (
//     <div className="relative z-30 w-full">
//       <div className="flex flex-col h-full">
//         <input
//           type={type}
//           id={name}
//           name={name}
//           value={value}
//           onChange={onChange}
//           disabled={disabled}
//           className={`peer block py-2.5 px-1 w-full text-sm text-gray-600 bg-transparent border-0 border-b-[2px] appearance-none focus:outline-none focus:ring-0 focus:border-[#FF6464] ${
//             disabled ? 'border-gray-300' : 'border-gray-400'
//           }`}
//           placeholder=" "
//           {...props} // Spread additional props to the input element
//         />
//         <label
//           htmlFor={name}
//           className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#FF6464] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
//         >
//           {label}
//         </label>
//       </div>
//     </div>
//   );
// }

// interface TextAreaGroupProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
//   label: string;
//   name: string;
//   value?: string;
//   onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
// }

// function TextAreaGroup({ label, name, value, onChange, disabled, ...props }: TextAreaGroupProps) {
//   return (
//     <div className="relative z-30 h-full">
//       <div className="flex flex-col h-full">
//         <textarea
//           id={name}
//           name={name}
//           value={value}
//           onChange={onChange}
//           disabled={disabled}
//           placeholder=" "
//           className={`peer resize-none flex-1 block py-2.5 px-1 w-full text-sm text-gray-600 bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#FF6464] ${disabled ? 'border-gray-300' : 'border-gray-400'} ${props.className}`}
//           {...props}
//         />
//         <label
//           htmlFor={name}
//           className="peer-focus:font-medium absolute text-sm text-gray-500 transition-transform duration-300 transform scale-75 -translate-y-12 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#FF6464] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
//         >
//           {label}
//         </label>
//       </div>
//     </div>
//   );
// }
export default ContactSection;
