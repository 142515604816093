import { motion } from 'framer-motion';
import Image from 'next/image';
import { useTranslation } from 'react-i18next'; // Assuming useTranslation for i18n
import BackgroundImage from './BackgroundImage';
import Link from 'next/link';
import { ExtProjects } from './page';
import ReactMarkdown from 'react-markdown';
import {
  CustomH4,
  CustomH5,
  CustomParagraph,
  components,
  reactMarkdownConfig,
  withCustomTextColor,
} from '@/lib/utils/strapi';
import remarkGfm from 'remark-gfm';

export const PortfolioSection = ({ projects, gigs }: { projects: ExtProjects; gigs: string }) => {
  const { t } = useTranslation();

  return (
    <div className="bg-content1 py-20 lg:px-6">
      <div className="container mx-auto px-4 lg:px-8">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="text-center mt-6 ">
            <h3 className="text-2xl mb-5 drop-shadow-[0_1.2px_1.2px_rgba(255,10,0,0.9)]">
              {t('portfolio.title')}
            </h3>
          </div>
          <ReactMarkdown className="prose max-w-full my-12 px-8" {...reactMarkdownConfig}>
            {gigs}
          </ReactMarkdown>
          <h2 className="mt-6 mb-14 text-center font-semibold">
            {t('portfolio.own_projects_title')}
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {projects.map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: 0.1 * index }}
              >
                <PortfolioItem {...item} />
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

const PortfolioItem = (project: ExtProjects[number]) => {
  return (
    <motion.div
      initial={{ scale: 0 }}
      whileHover={{
        scale: 1.03,
        x: 0,
        y: -5,
        // borderRadius: '0.25rem',
        boxShadow: '3px 3px 0 rgba(0, 0, 0, 0.4)',
      }}
      // whileHover={{
      //   scale: [1, 2, 2, 1, 1],
      //   rotate: [0, 0, 270, 270, 0],
      //   borderRadius: ['20%', '20%', '50%', '50%', '20%'],
      // }}
      animate={{ rotate: 360, scale: 1 }}
      transition={{ duration: 0.1, type: 'spring', stiffness: 260, damping: 20 }}
      className="col-span-1 rounded overflow-hidden h-full"
    >
      <Link href={`/project/${project.attributes.cz_project.data.attributes.slug}`} scroll={true}>
        <BackgroundImage
          image={
            <Image
              src={`${process.env.NEXT_PUBLIC_STRAPI_SERVER}${project.attributes.images?.data[0].attributes?.formats.small.url}`}
              alt={
                project.attributes.images?.data[0].attributes?.alternativeText || 'project image'
              }
              className="object-cover object-center hover:opacity-30"
              fill
            />
          }
          className={''}
        >
          <div className="text-white text- z-20 flex w-full flex-col justify-center py-12 drop-shadow-[0_1.2px_1.2px_rgba(100,10,0,0.6)]">
            <div className="p-4 ">
              <h2 className="text-lg font-semibold">{project.attributes.title}</h2>
              <ReactMarkdown
                className="prose max-w-full text-white"
                components={{
                  ...components,
                  p: withCustomTextColor(
                    CustomParagraph,
                    'text-white drop-shadow-[0_1.2px_1.2px_rgba(100,10,0,0.6)]'
                  ),
                  h5: withCustomTextColor(
                    CustomH5,
                    'text-white drop-shadow-[0_1.2px_1.2px_rgba(100,10,0,0.6)]'
                  ),
                }}
                remarkPlugins={[remarkGfm]}
              >
                {project.attributes.text}
              </ReactMarkdown>
              {/* <p className="text-sm">{project.attributes.text}</p> */}
            </div>
          </div>
        </BackgroundImage>
      </Link>
    </motion.div>
  );
};
