import React, { useState } from 'react';
import Image from 'next/image';
import { arrayCeil } from '@/lib/utils/helper';
import { Button } from '@nextui-org/react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

export default function Hero() {
  const [heroImage, setHeroImage] = useState('blurrystock-HIbAmybJHVs-unsplash.jpg');
  //   const imageSizes = [600, 1280, 1920];

  //   const { observe, unobserve, width, height, entry } = useDimensions({
  //     onResize: ({ observe, unobserve, width, height, entry }) => {
  //       setHeroImage(`hero-${arrayCeil(imageSizes, width)}.jpg`);

  //       unobserve(); // To stop observing the current target element
  //       observe(); // To re-start observing the current target element
  //     },
  //   });
  // const text = 'Framer Motion is a really cool tool'.split('');
  const { t } = useTranslation();
  const text = t('Welcome').split('');
  const subtext = t('header.subheadertext').split('');

  return (
    <div
      //   ref={observe}
      className="w-full h-screen flex justify-center items-center overflow-hidden relative bg-black"
    >
      <Image src={`/${heroImage}`} alt="Hero Image" className="opacity-70 object-cover" fill />
      <div className="flex flex-col justify-center items-center text-xl sm:text-3xl md:text-5xl text-white font-bold drop-shadow-lg px-3">
        <div className="">
          {text.map((el, i) => (
            <motion.span
              className=" drop-shadow-[0_1.2px_1.2px_rgba(255,10,0,0.9)]"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: i / 10,
              }}
              key={i}
            >
              <span>{el === ' ' ? <span>&nbsp; </span> : el} </span>
            </motion.span>
          ))}
        </div>
        {/* <div className=" text-center text-3xl md:text-5xl text-white font-bold drop-shadow-lg">
          WELCOME TO <br />
          <span className="text-primary">MY COMPANY</span>
        </div> */}
        <p className="mt-5 text-center text-lg text-white opacity-90 font-bold drop-shadow-lg px-3">
          {subtext.map((el, i) => (
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: i / 15,
              }}
              key={i}
            >
              <span>{el === ' ' ? <span>&nbsp; </span> : el}</span>
            </motion.span>
          ))}
        </p>
        {/* <Button className="mt-4" href="/">Get Started</Button> */}
      </div>
    </div>
  );
}
