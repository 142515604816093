// BackgroundImage.tsx
import Image from 'next/image';
import { ComponentPropsWithoutRef, ReactElement, ReactNode } from 'react';

interface BackgroundImageProps {
  children?: ReactNode;
  className?: string;
  image: ReactElement<typeof Image>;
}

type BackgroundImageAdditionalProps = Omit<
  ComponentPropsWithoutRef<'div'>,
  keyof BackgroundImageProps
>;

export default function BackgroundImage({
  children,
  className,
  image,
  ...props
}: BackgroundImageProps & BackgroundImageAdditionalProps) {
  return (
    <div className={'relative overflow-hidden h-full' + className} {...props}>
      <div className="absolute inset-0">{image}</div>
      <div className="z-10 flex h-full justify-center">{children}</div>
    </div>
  );
}
